body {
  margin: 0;
  font-family: 'Open Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@media (max-width: 426px) {
  .hide-on-mobile {
    display: none;
  }
}

@media (min-width: 426px) {
  .hide-on-web {
    display: none;
  }
}